import React, { Component } from 'react';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'

//const position = [35.7223666, 51.4166838];

class MyMap extends Component {

    constructor(props) {
        super(props);
        this.handleMessage = this.handleMessage.bind(this);
        document.addEventListener("message", this.handleMessage, false);
        setTimeout(() => {
            window.postMessage("start", "*");
            try {
                window.ReactNativeWebView.postMessage("start")
            }
            catch{ 
                alert('ddd')
            }
            //alert('as');
        }, 500);

        this.state = { markers: [] };
        window.handleMessage = this.handleMessage;
    }

    // state = {
    //     lat: 35.7223666,
    //     lng: 51.4166838,
    //     zoom: 16,
    // }

    handleMessage(e) {
        var data = JSON.parse(e.data);
        this.setState({
            markers: data
        });
    }

    handleClick = (e) => {
        const map = this.mapRef;
        //console.log(map);
        if (map != null) {
            //map.leafletElement.locate()
            // this.setState({
            //     hasLocation: true,
            //     latlng: e.latlng,
            // })
            // window.postMessage(e.latlng.lat + ", " + e.latlng.lng)
            //window.alert( e.latlng.lat + ", " + e.latlng.lng);
        }
    }

    handleLocationFound = (e) => {
        alert('found')
        this.setState({
            hasLocation: true,
            latlng: e.latlng,
        })
    }

    getMarker(item, index) {
        return (
            <Marker position={item.latlng} key={index} ref={marker => marker.leafletElement.openPopup()}>
                <Popup> <div style={{ color: 'red', textAlign: 'center', fontSize: 15, textDecoration: 'none' }}>
                    <a href={"https://www.waze.com/ul?q=" + item.latlng[0] + "," + item.latlng[1]}>{item.Message}</a>
                    {/* {item.Message} */}
                </div>
                </Popup>
            </Marker>
        );
    }

    //   render() {
    //     const marker = this.state.hasLocation ? (
    //       <Marker position={this.state.latlng}>
    //         <Popup>You are here</Popup>
    //       </Marker>
    //     ) : null

    //     return (
    //       <Map
    //         center={this.state.latlng}
    //         length={4}
    //         onClick={this.handleClick}
    //         onLocationfound={this.handleLocationFound}
    //         ref={this.mapRef}
    //         zoom={13}>
    //         <TileLayer
    //           attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    //           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    //         />
    //         {marker}
    //       </Map>
    //     )
    //   }
    render() {
        // const marker = this.state.hasLocation ? (
        //     <Marker position={this.state.latlng}>
        //         <Popup>You are here</Popup>
        //     </Marker>
        // ) : null;

        if (this.state.markers.length === 0)
            return (
                <div>
                    Map
                </div>
            );
        //alert(this.state.markers[0].latlng[0]);
        return (

            <Map center={this.state.markers[0].latlng} zoom={this.state.markers[0].zoom} minZoom={13} maxZoom={19} id="map"
                zoomControl={false} attribution={false}
                onClick={this.handleClick}
                onLocationfound={this.handleLocationFound}
                ref={ref => this.mapRef = ref}
            >
                <TileLayer
                    prefix={false}
                    //attribution="{false}"
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {/* <Marker position={position}>
                    <Popup>
                        A pretty CSS3 popup. <br /> Easily customizable.
                        <a href={"https://www.waze.com/ul?q=35.7223666,51.4166838"}>Open</a>
                    </Popup>
                </Marker> */}
                {/* {marker} */}
                {this.state.markers.map((item, index) => this.getMarker(item, index))}
            </Map>
        );
    }
}

export default MyMap;